import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from "web3";

import Img_nako from '../assets/imgs/nako.png';
import Img_unknown from '../assets/imgs/nft.png';
import Icon_tw from '../assets/imgs/icon_tw.png';
import Icon_dc from '../assets/imgs/icon_dc.png';
// import Btn_burn_img from '../assets/imgs/btn_burn.png';

import Modal from "./modal/Modal";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import 'react-notifications/lib/notifications.css';

import axios from 'axios';

import "../App.css";

import Spinner from 'react-bootstrap/Spinner';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
const BN = require('bn.js'); // BigNumber library

const RPC_URLS = {
  1: process.env.RPC_URL_1,
  420: process.env.RPC_URL_420,
};

const walletconnect =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? new WalletConnectConnector({
        rpc: { 1: RPC_URLS[1] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      })
    : new WalletConnectConnector({
        rpc: { 420: RPC_URLS[420] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      });

const DISCORD_LINK = "http://discord.gg/PmWf27cY6p";
const TWITTER_LINK = "https://twitter.com/FluffyHUGS_prj/";

/** Do not destructure env variables */
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const MINT_PRICE = process.env.REACT_APP_MINT_PRICE;
const GAS_PRICE_MARGIN = 0.01;

const acceptedChains = ENVIRONMENT === "development" ? [3, 4, 5, 42] : [1];
const NFT_ADDRESS = ENVIRONMENT === "development" ? process.env.REACT_APP_GOERLI_ADDRESS : process.env.REACT_APP_MAINNET_ADDRESS;
const injected = new InjectedConnector({ supportedChainIds: acceptedChains });

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../helper/abi.json");

const contract = new web3.eth.Contract(contractABI, NFT_ADDRESS);

// const NOT_CLAIMABLE = 0;
// const ALREADY_CLAIMED = 1;
// const CLAIMABLE = 2;
const MAX_MINT_COUNT = 5;
const PRESALE_DEFAULT_COUNT = 1;
const PUBLICSALE_DEFAULT_COUNT = 1;
// const INITIAL_TOTAL_PRICE = 0.025;

const MINT_PRESALE_PRICE = 0.0089;
const MINT_PUBLICSALE_PRICE = 0.015;

var freeMint = false;

const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
let whitelist = require("../helper/whitelist.json");

const leaves = whitelist.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });

function Home() {
  const [loading, setLoading] = useState(false);
  const [minting, setMinting] = useState(false);
  // const [burning, setBurning] = useState(false);
  const [count, setCount] = useState(PUBLICSALE_DEFAULT_COUNT);
  const [totalPrice, setTotalPrice] = useState(MINT_PRICE);
  const [totalSupply, setTotalSupply] = useState(0);

  // const [whitelistClaimable, setWhitelistClaimable] = useState(NOT_CLAIMABLE);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);
  const [whitelistValid, setWhitelistValid] = useState(false);
  const [whitelistProof, setWhitelistProof] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [mintPrice, setMintPrice] = useState(MINT_PRICE);
  const [saleActive, setSaleActive] = useState(false);
  const [presaleActive, setPresaleActive] = useState(false);
  // const [checked, setChecked] = useState(false);
  const [lastMintItem, setLastMintItem] = useState({
    maxCount: 0,
    mintedCount: 0
  })

  const { active, account, activate } = useWeb3React();

  const [isOpen, setIsOpen] = useState(false);


  // useEffect(async() => {
  //   getMintStatus();
  // }, []);

  // const getMintStatus = async(event) => {
  //   await axios.get("/api/mintSwitchStatus")
  //   .then((res) => {
  //     if(res.data.status == 0) setChecked(false);
  //     else if(res.data.status == 1) setChecked(true);
  //   })
  //   .catch(function(error) {
  //     console.log(error);
  //   });
  // }

  const showNotification = (message) => {
    if (message.includes("User denied transaction")) {
      NotificationManager.warning("User denied transaction");
    }
    else if (message.includes("Not enough NFTs left")) {
      NotificationManager.error("残り○点しか購入出来ません");
    }
    else if (message.includes("Not enough ether to purchase NFTs")) {
      NotificationManager.error("Not enough ether to purchase NFTs!", "NFTを購入するためのETHが不足しています。");
    }
    else if (message.includes("insufficient funds for gas")) {
      NotificationManager.error("Not enough ether to purchase NFTs!", "NFTを購入するためのETHが不足しています。");
    }
    else {
      NotificationManager.error("Transaction is failed");
    }
  }

  const enterPresale = () => {
    async function checkIfClaimed() {
      contract.methods
        .claimed(account)
        .call({ from: account })
        .then((result) => {
          setAlreadyClaimed(result);
          if (result) {
            NotificationManager.info("Already claimed");
          }
        })
        .catch((err) => {
          setAlreadyClaimed(false);
        });
    }

    checkIfClaimed();

    // setCount(whitelist[account]);
    setCount(PRESALE_DEFAULT_COUNT);

    const leaf = keccak256(account);
    const proof = merkleTree.getHexProof(leaf);
    const root = merkleTree.getHexRoot();

    // console.log("ROOT", root);

    // just for front-end display convenience
    // proof will be validated in smart contract as well
    setWhitelistValid(merkleTree.verify(proof, leaf, root));

    setWhitelistProof(proof);
  }

  useEffect(()=>{
    if (!account) {
      return;
    }
    if (saleActive) {
      setMintPrice(MINT_PUBLICSALE_PRICE);
      setTotalPrice(MINT_PUBLICSALE_PRICE * PRESALE_DEFAULT_COUNT);
    }
    if (presaleActive) {
      setMintPrice(MINT_PRESALE_PRICE);
      setTotalPrice(MINT_PRESALE_PRICE * PRESALE_DEFAULT_COUNT);
    }
  }, [account, presaleActive, saleActive]);

  useEffect(async () => {
    if (!account) {
      return;
    }
    var token_list = await getTokensOfOwner();
    setTokenList(token_list);
    mintSettingList();
  }, [account]);

  useEffect(async () => {
    if (!account || !tokenList) {
      return;
    }

    getImage();
  }, [tokenList]);

  const mintSettingList = async() => {
    const resTotalSupply = await getTotalSupply();
    let totalSupplyCount = 0;
    if(resTotalSupply.success){
      totalSupplyCount = resTotalSupply.status;
    } 
    await axios.get("/api/mintSettingList")
      .then((res) => {
        if (res && res.data && res.data.status && res.data.data.length > 0){
          let mintHistory = res.data.data;
          let lastItem = mintHistory[mintHistory.length - 1];
          let startOfLastItem = lastItem.mintMaxCount - lastItem.mintCount;
          lastItem.mintedCount = totalSupplyCount - startOfLastItem;
          // setData(mintHistory);
          setLastMintItem({...lastMintItem, maxCount:lastItem.mintCount, mintedCount:lastItem.mintedCount })
        }
      })
      .catch(function(error) {
        console.log(error);
        NotificationManager.error("Error!");
      });
  }
  
  const getTotalSupply = async() => {
    return contract.methods
      .totalSupply()
      .call({ from: account })
      .then((result) => {
        return {
          success: true,
          status: result,
        };
      })
      .catch((err) => {
        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  }

  const getTokensOfOwner = async () => {
    return await contract.methods
    .tokensOfOwner(account)
    .call()
    .then((result) => {
      return result;
    })
    .catch((err) => {
      showNotification(err.message);
      console.error("err", err);
    });
  }

  const getTokenURI = async (id) => {
    return contract.methods
    .tokenURI(id)
    .call()
    .then((res) => {
      return res
    })
    .catch((err) => {
      showNotification(err.message);
      console.error("err", err);
    });
  }

  const getImage = async() => {
    setLoading(true);

    const asyncRes = await Promise.all(tokenList.map(async (item) => {
      return await getTokenURI(Number(item)).then(async image => {
        var data = {};
        // if (process.env.REACT_APP_ENVIRONMENT === "production") {
        const res = await axios.post("/api/image_list", {data: image});
        if(res.data && res.data.status)
          data = {"image": res.data.image_url, "id": item, "status": false};
        else
          data = {"image": Img_unknown, "id": item, "status": false};
        
        return data;
      });
    }));
    setLoading(false);
    // console.log(asyncRes)
    // NotificationManager.success("Get token list successfully!");
    setFullList(asyncRes)
  }

  useEffect(async () => {
    async function validateSaleActive() {
      contract.methods
        .saleActive()
        // .call({ from: account })
        .call()
        .then((result) => {
          setSaleActive(!!result);

          if (result) {
            setPresaleActive(false);
            setCount(PUBLICSALE_DEFAULT_COUNT);
            // setTotalPrice(mintPrice * PRESALE_DEFAULT_COUNT);
          }
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }

    validateSaleActive();
  }, []);

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function validatePresaleActive() {
      contract.methods
        .presaleActive()
        .call({ from: account })
        .then((result) => {
          setPresaleActive(!!result);
          if (result) {
            enterPresale();
          }
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }

    validatePresaleActive();

  }, [account]);

  useEffect(async () => {
    if (!active || !account) {
      return;
    }

    // async function validateSaleActive() {
    //   contract.methods
    //     .saleActive()
    //     .call({ from: account })
    //     .then((result) => {
    //       setSaleActive(!!result);
    //       if (result) {
    //         setPresaleActive(false);
    //         setCount(PUBLICSALE_DEFAULT_COUNT);
    //         // setTotalPrice(mintPrice * PRESALE_DEFAULT_COUNT);
    //       }
    //     })
    //     .catch((err) => {
    //       showNotification(err.message);
    //       console.error("err", err);
    //     });
    // }

    // validateSaleActive();
    // getTotalSupply();
    const resTotalSupply = await getTotalSupply();
    if (resTotalSupply.success){
      let totalSupplyCount = resTotalSupply.status;
      setTotalSupply(resTotalSupply.status);
    }
    else {
      NotificationManager.success("Get Total Supply failed!");
    }

  }, [account]);

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function validateMintPrice() {
      if (presaleActive) {
        contract.methods
        .preSalePrice()
        .call({ from: account })
        .then((result) => {
          const price = web3.utils.fromWei(result);

          setMintPrice(price);
          setTotalPrice(price * PRESALE_DEFAULT_COUNT);
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
      }
      else if (saleActive) {
        contract.methods
        .price()
        .call({ from: account })
        .then((result) => {
          const price = web3.utils.fromWei(result);

          setMintPrice(price);
          setTotalPrice(price * PRESALE_DEFAULT_COUNT);

        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
      }
    }

    validateMintPrice();
  }, [account, presaleActive, saleActive]);

  function connect() {
    setIsOpen(true);
  }

  async function connectMetamask() {
    setIsOpen(false);

    await activate(injected)
    .then((response) => {
    })
    .catch((error) => {
      console.log(error)
    })    
  }

  async function connectWalletConnect() {
    setIsOpen(false);

    await activate(walletconnect, undefined, true)
    .then((response) => {
    })
    .catch((error) => {
    })

    console.log("WALLET CONNECT");
  }
  
  const preSale = async (account, proof, amount, amountToWei) => {
    return contract.methods
      .preSale(proof, amount)
      .send({ from: account, value: amountToWei })
      .then((res) => {
        setMinting(false);
        NotificationManager.success("Mint is successful!");

        return {
          success: true,
          status:
            `✅ Check out your transaction on Etherscan: https://etherscan.io/tx/` +
            res,
        };
      })
      .catch((err) => {
        setMinting(false);
        showNotification(err.message);

        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  };

  const realMintNFTs = async (account, amount, amountToWei) => {
    return contract.methods
      .mintNFTs(amount)
      .send({ from: account, value: amountToWei })
      .then(async (result) => {
        setMinting(false);
        mintSettingList();
        var token_list = await getTokensOfOwner();
        setTokenList(token_list);
        setTotalSupply(totalSupply + amount);
        await axios.post("/api/mintAlUpdate", {wallet: account, minted: amount});

        NotificationManager.success("Mint is successful!");

        return {
          success: true,
          status:
            `✅ Check out your transaction on Etherscan: https://etherscan.io/tx/` +
            result,
        };
      })
      .catch((err) => {
        setMinting(false);
        showNotification(err.message);

        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  };

  const onClickMint = async () => {
    const res = await axios.post("/api/mintMaxCount", {wallet: account});
    let mintMaxCount = 0;
    let alMaxCount = 0;
    let alMinted = 0;
    if(res && res.data && res.data.status){
      mintMaxCount = res.data.data.mintMaxCount;
      alMaxCount = res.data.data.alMaxCount;
      alMinted = res.data.data.alMinted;
    }
    const resTotalSupply = await getTotalSupply();
    if(resTotalSupply.success){
      let totalSupplyCount = resTotalSupply.status;
      if(Number(totalSupplyCount) + count <= mintMaxCount) {
        // AL List Checking
        if (alMaxCount == 0) {
          let msg = '購入出来ません';
          NotificationManager.error(msg);
          return;
        }
        // else if (alMaxCount > 0 && tokenList.length + count > alMaxCount) {
        else if (alMaxCount > 0 && alMinted + count > alMaxCount) {
          // let remain = alMaxCount - tokenList.length;
          let remain = alMaxCount - alMinted; // calculate count from CSV upload
          let msg = remain > 0 ? '残り' + remain + '点しか購入出来ません' : 'もう購入出来ません';
          NotificationManager.error(msg);
          return;
        }

        if (saleActive) {
          onPublicMint();
        } else if (presaleActive) {
          onPreSale();
        }
      }
      else if (mintMaxCount == 0) {
        NotificationManager.error('まだ購入出来ません');
      }
      else {
        let remain = mintMaxCount - Number(totalSupplyCount);
        let msg = remain > 0 ? '残り' + remain + '点しか購入出来ません' : 'もう購入出来ません';
        // NotificationManager.error("Mint Count is less than MintMaxCount!");
        NotificationManager.error(msg);
      }
    }
    else {
      NotificationManager.success("Get Total Supply failed!");
    }
  };

  const onPreSale = async () => {
    // const { success, status } = await preSale(account, whitelistProof, count);
    const price = (count * mintPrice).toString();
    const amountToWei = freeMint ? 0 : web3.utils.toWei(price, "ether");
    const checkPrice = ((freeMint ? 0 : count * mintPrice) + GAS_PRICE_MARGIN).toString();
    const checkToWei = web3.utils.toWei(checkPrice, "ether");

    setMinting(true);

    // Check ETH balance
    contract.methods.preSale(whitelistProof, count)
      .estimateGas({from: account, value: checkToWei})
      .then(function(gasAmount) {
        const { success, status } = preSale(account, whitelistProof, count, amountToWei);
        mintSettingList();
      })
      .catch(function(error) {
        setMinting(false);
        showNotification(error.message);

        return {
          success: false,
          status: "😥 Something went wrong: ",
        };
      });
  };

  const estimateAndMint = async () => {
    const mintPriceWei = web3.utils.toWei(mintPrice, "ether");
    const mintPriceBN = new BN(mintPriceWei);
    const priceBN = mintPriceBN.mul(new BN(count));

    const marginToWei = web3.utils.toWei(GAS_PRICE_MARGIN.toString(), "ether");
    const checkPriceBN = priceBN.add(new BN(marginToWei));

    contract.methods.mintNFTs(count)
      .estimateGas({from: account, value: checkPriceBN})
      .then(function(gasAmount) {
        const { success, status } = realMintNFTs(account, count, priceBN);
      })
      .catch(function(error) {
        setMinting(false);
        showNotification(error.message);

        return {
          success: false,
          status: "😥 Something went wrong: ",
        };
      });
  }

  const onPublicMint = async () => {
    // const { success, status } = await mintNFTs(account, count);
    setMinting(true);

    const res = await axios.get("/api/remain")
    .then((res) => {
      if (res && res.data && res.data.status) {
        estimateAndMint();
      }  
    })
    .catch(function(error) {
      NotificationManager.error("Connection timeout");
      setMinting(false);
    });
  };

  const handlePlusClick = () => {
    setCount(count >= MAX_MINT_COUNT ? MAX_MINT_COUNT : count + 1);
    setTotalPrice(count >= MAX_MINT_COUNT ? mintPrice * MAX_MINT_COUNT : (count+1) * mintPrice);
  };
  const handleMinusClick = () => {
    setCount(count <= 1 ? 1 : count - 1);
    setTotalPrice(count <= 1 ? mintPrice : ((count-1) * mintPrice));
  };
  const handleMaxClick = () => {
    setCount(MAX_MINT_COUNT);
    setTotalPrice(mintPrice * MAX_MINT_COUNT );
  };

  // const Burn = async (account, id, index) => {
  //   setBurning(true);

  //   return await contract.methods
  //     .burn(id)
  //     .send({ from: account })
  //     .then(async (res) => {
  //         NotificationManager.success("Burn is successful!");
  //         var token_list = await getTokensOfOwner();
  //         setTokenList(token_list);
  //         setBurning(false);

  //         return {
  //           success: true,
  //           status: "Burn is successful!"
  //         };
  //       })
  //       .catch((err) => {
  //         let tmp = [...fullList];
  //         tmp[index]['status'] = false;
  //         setFullList(tmp)
  //         setBurning(false);
  //         showNotification(err.message);

  //         return {
  //           success: false,
  //           status: "😥 Something went wrong: " + err.message,
  //         };
  //       });
  // };
  
  // const onBurnClick = async (id, index) => {
  //   let tmp = [...fullList];
  //   tmp[index]['status'] = true;
  //   setFullList(tmp)

  //   const { success, status } = await Burn(account, id, index);
  //   // console.log(status);
  // }

  const openSeaClick = () => {
    window.open('https://opensea.io/collection/fluffy-hugs89');
  }

  const todosList = fullList.map((item, index) => (
    <div className="flex flex-direction-column flex-25 mb-10" key={index}>
      <img src={item.image} className="hugs-item" alt="hug item image1" />
      {/* {
        burning ? 
          <img src={Btn_burn_img} className="hugs-btn" style={{opacity: 0.7}}></img> 
          :
          <img src={Btn_burn_img} className="btn hugs-btn" onClick={()=>onBurnClick(item.id, index)}></img> 
      } */}
    </div>
  ));

  return (
    <div className="container">
      <div className="up_bg">
        <div className="counter center flex flex-direction-column justify-between text-center">
          <div className="social_icon_position">
            <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
              <img src={Icon_tw} className="social_icon mr-15" alt='icon tw'/>  
            </a>
            <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
              <img src={Icon_dc} className="social_icon" alt='icon dc'/>  
            </a>
          </div>
          <img src={Img_nako} className="nako-img" alt="nako image1" />
          {
            // checked ?
            saleActive ?
              <>
                <span className="box_title ft-cursive">Mint NFT</span>
                {
                  totalSupply ?
                    <div>
                      <span className="box_title_desc">Total Supply： 3,333</span><br/>
                      {/* <span className="box_title_desc">Limited Supply： {lastMintItem.mintedCount}&nbsp;/&nbsp;{lastMintItem.maxCount}</span> */}
                      {/* <span className="box_title_desc">Limited Supply： 10&nbsp;/&nbsp;30</span> */}
                    </div>
                    :
                    <span className="box_title_desc">Total Supply： 3,333 </span>
                }
                {
                  freeMint ? 
                  (<div className="text-center color-white">
                    <span className="box_input_txt ft-cursive">Price: FREE + GAS</span>
                    <br />
                    <span className="box_input_txt ft-cursive">Max per Address: 5</span>
                  </div>)
                  :
                  (
                    
                  <div className="flex justify-between">
                    <span className="box_input_txt ft-cursive">Price</span>
                    {
                      account ?
                      <span className="box_input_txt ft-cursive">{mintPrice}ETH</span>
                      :
                      <span className="box_input_txt ft-cursive"> ―― ETH</span>
                    }
                  </div>
                  )
                }

                <div className="divide" />

                <div className="flex justify-between items-center">
                  <span className="box_input_txt ft-cursive">Amount</span>
                  <button
                    className="btn calc-button-minus tab-left tab-right cursor-pointer"
                    disabled={freeMint}
                    onClick={handleMinusClick}
                  >
                    -
                  </button>
                  <input
                    className="amount_txt"
                    disabled
                    value={count}
                    id="SI"
                  />
                  <button
                    className="btn calc-button-plus tab-left tab-right cursor-pointer"
                    disabled={freeMint}
                    onClick={handlePlusClick}
                  >
                    +
                  </button>
                  <button
                    className="btn max-button cursor-pointer ft-cursive"
                    disabled={freeMint}
                    onClick={handleMaxClick}
                  >
                    Max
                  </button>
                
                </div>

                <div className="divide" />

                <div className="flex justify-between">
                  <span className="box_input_txt ft-cursive">Total</span>
                  {
                    account ?
                    <span className="box_input_txt ft-cursive">{ freeMint ? '0.0' : parseFloat(totalPrice).toFixed(4) } ETH</span>
                    :
                    <span className="box_input_txt ft-cursive"> ―― ETH</span>

                  }
                </div>

                <div className="divide" />

                {active ? (
                  <button
                    type="button"
                    disabled={
                      minting ||
                      alreadyClaimed ||
                      (presaleActive && !whitelistValid) ||
                      (!presaleActive && !saleActive)
                    }
                    onClick={onClickMint}
                    className="btn redbutton"
                  >
                    { minting
                      ? "Waiting confirmation."
                      : presaleActive
                      ? "Pre Sale"
                      : "Buy"
                    }
                  </button>
                ) : (
                  <button type="button" onClick={connect} className="btn redbutton ft-cursive">
                    Connect
                  </button>
                )}
              </> 
              :
              <>
                <div style={{width: '390px', margin: 'auto', color: 'white', fontWeight: 'bold'}}>
                  現在Fluffy HUGSはmintす<br />
                  る事が出来ません。
                </div>
                <button type="button" onClick={openSeaClick} className="btn redbutton ft-cursive">

                  OPEN SEA
                </button>
              </>
            }
            {
              account && (
                <div style={{marginTop: '10px'}}>
                  <a href={`https://paypiement.xyz/projects/cf66e07b-52cb-4066-b9c3-099ee0a4ce8f?quantity=${count}&recipientAddress=${account}`} data-count={count} rel="noreferrer" target="_blank" className="btn redbutton btn_credit">クレジットで購入</a>
                </div>
              )
            }
           {/* <span className="box_bottom_txt">Pre Sale: Nov.28 21:00～（JST）</span>
           <span className="box_bottom_txt">Public Sale: Nov.29 21:00〜（JST）</span> */}
        </div>
      </div>

      {/* {isOpen && <Modal setIsOpen={setIsOpen} connectMetamask={connectMetamask} connectWalletConnect={connectWalletConnect}/>}
       */}
       <Modal open={isOpen} setIsOpen={setIsOpen} connectMetamask={connectMetamask} connectWalletConnect={connectWalletConnect} />

      <div className="down_bg text-center">
        <span className="bottom_title">MY HUGS</span>
          {
            loading ? 
              <Spinner animation="border" className="flex m-auto mt-10 mb-10" /> 
                : 
                todosList.length > 0 ?
                  <div className="flex flex-wrap mt-10 overflow-y-auto mb-10 img_list_height">
                    {todosList}
                  </div>
                  :
                  null
          }
        {/* <div className="bottom_box mt-10">
          <span className="bottom_desc">
          ＜NFT購入価格の払い戻しについて＞<br />
          Fluffy HUGSのアートワークのNFTを当ページで購入いただいた場合、今後あなたがもう必要ないと考えた場合、購入価格の50%を返金する仕組みをご用意しています。
          今後当サイトよりBURN（消去）いただくことで、あなたのウォレットに購入時の価格の50%のイーサリアムを自動で送金させていただきます。<br />

          &lt;About refund of NFT purchase price&gt;<br />
          If you purchase a Fluffy HUGS artwork NFT on this page, 
          we have prepared a mechanism to refund 50% of the purchase price if you think that you no longer need it in the future.<br />
          From now on, by BURN (deleting) from this site, we will automatically remit 50% of the purchase price in Ethereum to your wallet.
          </span>
        </div> */}
      </div>
      <NotificationContainer />
    </div>
  );
}

export default Home;
