import React, { useEffect, useState, useRef } from 'react';

import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import "react-notifications/dist/react-notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { TextField, Grid, Divider } from '@mui/material';

import axios from 'axios';

import Modal from '../modal/Modal'; 
import CsvModal from '../modal/CsvModal'; 
import { CSVLink } from 'react-csv';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Switch from '@mui/material/Switch';
import Img_up from '../../assets/imgs/upload.png';
import Img_down from '../../assets/imgs/download.png';

// mossad
const RPC_URLS = {
  1: process.env.RPC_URL_1,
  2: process.env.RPC_URL_2,
};

const walletconnect =
  process.env.REACT_APP_ENVIRONMENT == "production"
    ? new WalletConnectConnector({
        rpc: { 1: RPC_URLS[1] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      })
    : new WalletConnectConnector({
        rpc: { 420: RPC_URLS[420] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      });

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const acceptedChains = ENVIRONMENT === "development" ? [3, 4, 5, 42] : [1];
const NFT_ADDRESS = ENVIRONMENT === "development" ? process.env.REACT_APP_GOERLI_ADDRESS : process.env.REACT_APP_MAINNET_ADDRESS;
const injected = new InjectedConnector({ supportedChainIds: acceptedChains });

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../../helper/abi.json");

const contract = new web3.eth.Contract(contractABI, NFT_ADDRESS);

const Dashboard = () => {
  const [saveState, setSaveState] = useState(false);
  const [data, setData] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState('');
  // const [checked, setChecked] = useState(false);
  const [mintSwitching, setMintSwitching] = useState(false);
  const [saleActive, setSaleActive] = useState(false);
  const [totalMintedCount, setTotalMintedCount] = useState(0);

  const [isCsvOpen, setIsCsvOpen] = useState(false);
  const [uploadId, setUploadId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transactionData, setTransactionData] = useState([])
  const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const { active, account, activate, deactivate } = useWeb3React();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateList, setDateList] = useState([]);

  const showNotification = (message) => {
    if (message.includes("User denied transaction")) {
      NotificationManager.warning("User denied transaction");
    }
    else if (message.includes("caller is not the owner")) {
      NotificationManager.error("Not allowed");
    }
    else if (message.includes("is missing role")) {
      NotificationManager.error("Not allowed action");
    }
    else {
      // NotificationManager.error("Transaction is failed");
    }
  }

  useEffect(async() => {
    if (!account) {
      return;
    }
    getMintSettingList();
    getPrice();
  }, [account]);

  useEffect(async() => {
    axios.post("/api/mintStatusAdd", null)
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDateList(res.data.data);
        }
      })
      .catch(function(error) {
        NotificationManager.error("Error!");
      });
  }, []);

  // useEffect(async() => {
  //   getMintStatus();
  // }, []);
  
  useEffect(async () => {
    if (!active || !account) {
      return;
    }

    async function validateSaleActive() {
      contract.methods
        .saleActive()
        .call({ from: account })
        .then((result) => {
          setSaleActive(!!result);
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }

    validateSaleActive();

  }, [account]);

  // const getMintStatus = async(event) => {
  //   await axios.get("/api/mintSwitchStatus")
  //   .then((res) => {
  //     if (res.data.status == 0) setChecked(false);
  //     else if (res.data.status == 1) setChecked(true);
  //   })
  //   .catch(function(error) {
  //     console.log(error);
  //   });
  // }

  const handleMintOnOff = async(event) => {
    const res = await setMintOnOff(event.target.checked);
    // if (!res) return;

    // await axios.post("/api/mintSwitchStatus", {
    //   mintSwitchStatus: event.target.checked
    // })
    // .then((res) => {
    //   setChecked(res.data.status);
    // })
    // .catch(function(error) {
    //   console.log(error);
    // });
  }

  const connectMetamask = async() => {
    setIsOpen(false);

    await activate(injected)
    .then((res) => {
      NotificationManager.success("Metamask Wallet is connected");
    })
    .catch((ex) => {
      NotificationManager.error("Error", ex, 2000);
    })
  }

  const connectWalletConnect = async() => {
    setIsOpen(false);

    await activate(walletconnect, undefined, true)
    .then((res) => {
      NotificationManager.success("Connect Wallet is connected");
    })
    .catch((ex) => {
      NotificationManager.error("Error", ex, 2000);
    })
  }

  const disconnect = async() => {
    await deactivate();
  }

  const onClickAdd = () => {
    if (data.length > 0 && data[data.length - 1].sign === 0) return;
    setData([...data, {mintCount:'', mintMaxCount:0, mintedCount:0, price: price, date: '', sign: 0, al: 0, csvfilename: ''}]);
    setSaveState(true);
  }
  const onClickAddTime = () => {
    const newData = {
      fromDate:startDate.toString(),
      toDate:endDate.toString()
    }
    axios.post("/api/mintStatusAdd", newData)
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDateList(res.data.data);
        }
      })
      .catch(function(error) {
        NotificationManager.error("Error!");
      });
  }
  const onClickDeleteTime = (id) => {
    const deleteData = {
      id:id
    }
    axios.post("/api/mintStatusDelete", deleteData)
      .then((res) => {
        if (res && res.data && res.data.status) {
          setDateList(res.data.data);
        }
      })
      .catch(function(error) {
        NotificationManager.error("Error!");
      });
  }

  const handleChange = (index, event) => {
    if (event.target.name === 'mintCount' && event.target.value < 0) {
      NotificationManager.error("Input value should be greather than 0");
      return ;
    }
    let tmp = [...data];
    tmp[index][event.target.name] = event.target.value;
    setData(tmp);
  }

  const handleAL = (index, event) => {
    let tmp = [...data];
    tmp[index]['al'] = tmp[index]['al'] === 1 ? 0 : 1;
    setData(tmp);
  }

  const getTotalSupply = async() => {
    return contract.methods
      .totalSupply()
      .call({ from: account })
      .then((result) => {
        return {
          success: true,
          status: result,
        };
      })
      .catch((err) => {
        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  }

  const setMintOnOff = async(on) => {
    setMintSwitching(true);

    return contract.methods
      .setSaleActive(on)
      .send({ from: account })
      .then(async (result) => {
        setSaleActive(on);
        setMintSwitching(false);
        return {
          success: true,
          status: result,
        };
      })
      .catch((err) => {
        setMintSwitching(false);
        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  }

  const getPrice = async() => {
    return contract.methods
      .price()
      .call({ from: account })
      .then((result) => {
        let price = web3.utils.fromWei(result, "ether");
        setPrice(price);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onSetPrice = async (price, mintMaxCount, mintCount, prevMintedCount, prevId, al) => {
    // const price = price.toString();
    // const salePrice = '0.025';
    const salePrice = price.toString();
    const amountToWei = web3.utils.toWei(salePrice, "ether");
    
    return contract.methods
      .setPrice(amountToWei)
      .send({ from: account })
      .then(async (result) => {
        NotificationManager.success("Success to set price!");

        const newData = {
          mintCount : mintCount,
          mintMaxCount : mintMaxCount,
          mintedCount : 0,
          price : price,
          prevMintedCount : prevMintedCount,
          prevId : prevId,
          al: al
        }
  
        axios.post("/api/mintSettingList", newData)
          .then((res) => {
            if (res && res.data && res.data.status) {
              setData(res.data.data)
              setUpdating(false);
              setSaveState(false);
              getPrice();
            }
          })
          .catch(function(error) {
            NotificationManager.error("Error!");
            setUpdating(false);
            // setSaveState(false);
          });

        return {
          success: true,
          status:
            `✅ Check out your transaction on Etherscan: https://etherscan.io/tx/` +
            result,
        };
      })
      .catch((err) => {
        setUpdating(false);
        showNotification(err.message);

        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  };

  const onClickUpdate = async() => {
    setUpdating(true);
    if (!data[data.length -1].mintCount || !data[data.length -1].price) {
      NotificationManager.error("Please input MintCount or Price!");
      setUpdating(false);
      return ;
    }

    var mintCount = data[data.length -1].mintCount;
    var mintMaxCount = data[data.length -1].mintMaxCount;
    var price = data[data.length -1].price;
    var al = data[data.length -1].al;
    var prevId = '';
    var totalSupplyCount = 0;
    let prevMintedCount = 0;
    
    const resTotalSupply = await getTotalSupply();
    totalSupplyCount = resTotalSupply.status;

    if (data.length > 1) {
      prevId = data[data.length -2].id;
      let startOfLastItem = data[data.length -2].mintMaxCount - data[data.length -2].mintCount;
      prevMintedCount = totalSupplyCount - startOfLastItem;           
    }
    
    if (resTotalSupply.success) {
      // NotificationManager.success("Success to get total supply!");
      mintMaxCount = Number(mintCount) + Number(totalSupplyCount);

      if (mintMaxCount <= 3333) {
        const salePrice = price.toString();
        const amountToWei = web3.utils.toWei(salePrice, "ether");

        contract.methods.setPrice(amountToWei)
          .estimateGas({ from: account })
          .then(function(gasAmount) {
            // const { success, status } = onSetPrice(price);
            const { success, status } = onSetPrice(price, mintMaxCount, mintCount, prevMintedCount, prevId, al);
            // NotificationManager.success("Success to set price!");
          })
          .catch(function(error) {
            setUpdating(false);
            showNotification(error.message);
    
            return {
              success: false,
              status: "😥 Something went wrong: ",
            };
          });
      }  
      else {
        NotificationManager.error("Mint Max Count should be less than 3333!");
        setUpdating(false);
        // setSaveState(false);
      }
    }
    else {
      NotificationManager.error("Fail to get total supply!");
      setUpdating(false);
      // setSaveState(false);
    }
  }

  const getMintSettingList = async() => {
    const resTotalSupply = await getTotalSupply();
    let totalSupplyCount = 0;
    if (resTotalSupply.success) {
      totalSupplyCount = resTotalSupply.status;
      // NotificationManager.success("Sucess to get total supply!");

      setTotalMintedCount(resTotalSupply.status);
    } 
    else 
      NotificationManager.error("Fail to get total supply!");

    await axios.get("/api/mintSettingList")
      .then((res) => {
        if (res && res.data && res.data.status && res.data.data.length > 0) {
          let mintHistory = res.data.data;
          let lastItem = mintHistory[mintHistory.length - 1];
          let startOfLastItem = lastItem.mintMaxCount - lastItem.mintCount;
          lastItem.mintedCount = totalSupplyCount - startOfLastItem;
          setData(mintHistory);
        }
      })
      .catch(function(error) {
        console.log(error);
        NotificationManager.error("Error!");
      });
  }

  const handleCsvFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCsvUpload = () => {
    if (uploadId == 0) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('uploadId', uploadId);

    setIsCsvOpen(false);

    // Send the formData to the server using an HTTP request (e.g., axios)
    // Example: axios.post('/upload', formData)
    // Handle the server-side file upload in your Node.js server
    axios.post('/api/uploadCsv', formData)
      .then((response) => {
        // Handle successful response from the server
        getMintSettingList();

        NotificationManager.success("AL list is Uploaded");
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        NotificationManager.error("Error", "Error in uploading AL list", 2000);
      });    
  };

  const openUploadCsv = (event, id) => {
    setIsCsvOpen(true);
    setUploadId(id);
  }

  const downloadCsv = async (event, id) => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    await axios.post('/api/downloadCsv', { id: id })
      .then((res) => {
        let list = [];

        res.data.data.map((record) => {
          // list.push(record.wallet + ',' + record.mintMax);
          list.push({
            wallet: record.wallet,
            mintMax: record.mintMax
          });
        });
        setTransactionData(list);
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  }

  const todosList = data.map((item, index) => (
    <Grid container spacing={2} key={index} style={{marginBottom: '20px'}}>
      <Grid item xs={2} md={2}>
        <TextField 
          id="outlined-basic"
          label="Max Mint Count"
          name="mintCount"
          type="number"
          variant="outlined"
          value={item.mintCount}
          disabled={ item.sign === 1 ? true : false }
          onChange={(event)=>handleChange(index, event)}
          />
      </Grid>
      <Grid item xs={2} md={2}>
        <TextField id="outlined-basic" label="Minted Count" name="mintedCount" type="number" variant="outlined" value={item.mintedCount} disabled />
      </Grid>
      <Grid item xs={2} md={2}>
        <TextField id="outlined-basic" label="Price" name="price" variant="outlined" value={item.price} onChange={(event)=>handleChange(index, event)} disabled={ item.sign === 1 ? true : false } />
      </Grid>
      <Grid item xs={3} md={3}>
        <TextField id="outlined-basic" label="Setting Date" name="settingDate" variant="outlined" value={item.date} disabled/>
      </Grid>
      <Grid item xs={1} md={1}>
        <div>AL</div>
        <Switch
          disabled={ item.sign === 1 ? true : false }
          // checked={checked}
          checked={item.al === 1}
          onChange={(event)=>handleAL(index, event)}
        />
      </Grid>
      {item.sign === 1 && item.al ?
        <Grid item xs={2} md={2}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <button onClick={(event) => openUploadCsv(event, item.id)} style={{ border: 'none' }}>
                <img src={Img_up} className="csv-icon" alt="upload" />
              </button>
            </Grid>
            {item.csv === 1 ?
            <Grid item xs={6} md={6}>
              <button onClick={(event) => downloadCsv(event, item.id)} style={{ border: 'none' }}>
                <img src={Img_down} className="csv-icon" alt="download" />
              </button>
              <CSVLink
                data={transactionData}
                filename='AL_List.csv'
                className='hidden'
                ref={csvLink}
                target='_blank'
              />
            </Grid>
            : <></>
            }
          </Grid>
        </Grid>
        : <></>
      }
    </Grid>
  ));

  const renderDateList = dateList.map((item, index) => (
    <Grid container spacing={2} key={index} style={{marginTop: '20px', marginBottom: '20px'}}>
      <Grid item xs={4} md={4}>
          <DatePicker
            selected={new Date(item.fromDate)}
            selectsStart
            disabled
            timeInputLabel="Time:"
            dateFormat="yyyy-MM-dd h:mm aa"
            showTimeInput
          />
      </Grid>
      <Grid item xs={4} md={4}>
          <DatePicker
            selected={new Date(item.toDate)}
            selectsEnd
            minDate={new Date(item.fromDate)}
            disabled
            timeInputLabel="Time:"
            dateFormat="yyyy-MM-dd h:mm aa"
            showTimeInput
          />
      </Grid>
      <Grid item xs={3} md={3}>
        <button className="btn text-white" style={{background: '#17a2b8', borderRadius: '0px'}} onClick={()=>onClickDeleteTime(item.id)} >Delete</button>
      </Grid>
    </Grid>
  ));

  return (
    <section className="container">
      <div className="card mt-4">
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h4 className="card-header">Minting management system</h4>
          {
            active &&
            <Switch
              disabled={mintSwitching}
              // checked={checked}
              checked={saleActive}
              onChange={handleMintOnOff}
            />
          }
        </div>
        <div style={{marginLeft: '20px', marginTop: '20px', marginBottom: '20px'}}>
          <TextField id="outlined-basic" label="Total Mint Count" variant="outlined" type="number" value={3333}  style={{marginRight: '20px'}} />
          <TextField id="outlined-basic" label="Total Minted Count" variant="outlined" value={totalMintedCount} type="number" />
        </div>
        <Divider style={{background : 'black'}} />
        <div className="card-body ml-4 mr-4" style={{height: 'calc(100vh - 200px)', overflowY:'auto'}}>
          { todosList }
          <div className="justify-between mt-4" style={{display: 'flex'}}>
              <button className="btn text-white" style={{background: '#17a2b8', borderRadius: '0px'}} onClick={()=>onClickAdd()} >Add</button>
              {
                active ?
                <button className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={()=>disconnect()}>Disconnect</button>
                :
                <button className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={()=>setIsOpen(true)}>Connect</button>
              }
              <Modal open={isOpen} setIsOpen={setIsOpen} connectMetamask={connectMetamask} connectWalletConnect={connectWalletConnect} />
              <CsvModal open={isCsvOpen} setIsOpen={setIsCsvOpen} handleFileChange={handleCsvFileChange} handleUpload={handleCsvUpload}/>
              <button
                  className="btn btn-primary" 
                  style={{ borderRadius: '0px'}}
                  onClick={()=>onClickUpdate()} 
                  disabled={ !active || updating || !saveState }
                >
                  {
                    updating ? 'Updating ...' : 'Save'
                  }
              </button>
          </div>
          <br/>

          <h5>Minting schedule</h5>
          { renderDateList }
          <Grid container spacing={2} style={{marginTop: '20px', marginBottom: '20px'}}>
            <Grid item xs={4} md={4}>
            <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd h:mm aa"
                  showTimeInput
                />
            </Grid>
            <Grid item xs={4} md={4}>
            <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd h:mm aa"
                  showTimeInput
                />
            </Grid>
            <Grid item xs={3} md={3}>
            <button className="btn text-white" style={{background: '#17a2b8', borderRadius: '0px'}} onClick={()=>onClickAddTime()} >Add</button>
            </Grid>
        </Grid>
        </div>
      </div>
      
      <NotificationContainer />
    </section>
  );
};

export default Dashboard;