import React from "react";

const CsvModal = (props) => {
  const {open, setIsOpen, handleFileChange, handleUpload} = props

  return (
    <>
      <div className={open ===  false ? "cs-modal" : "cs-modal active"} data-modal="connect_wallet">
        <div className="cs-modal_in">
          <div className="cs-modal_container cs-white_bg">
            <button className="cs-close_modal cs-center cs-primary_bg" onClick={()=>setIsOpen(false)}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z" fill="white"/>
              </svg>
            </button>
            <div className="cs-wallet_secton text-center">
              <h2 className="cs-font_22 text-uppercase cs-normal cs-m0">Upload AL List CSV</h2>
              <div className="cs-height_25 cs-height_lg_25"></div>
              <input type="file" accept=".csv" onChange={handleFileChange} />
              <div className="cs-height_25 cs-height_lg_25"></div>
              <button className="btn redbutton cursor-pointer" onClick={handleUpload}>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CsvModal;