import React, { useEffect, useState, useRef } from 'react';

import Web3 from "web3";

import "react-notifications/dist/react-notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { TextField, Grid, Divider } from '@mui/material';

import axios from 'axios';

import { CSVLink } from 'react-csv';

import Img_down from '../../assets/imgs/download.png';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const acceptedChains = ENVIRONMENT === "development" ? [3, 4, 5, 42] : [1];
const NFT_ADDRESS = ENVIRONMENT === "development" ? process.env.REACT_APP_GOERLI_ADDRESS : process.env.REACT_APP_MAINNET_ADDRESS;
// const injected = new InjectedConnector({ supportedChainIds: acceptedChains });

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../../helper/abi.json");

const contract = new web3.eth.Contract(contractABI, NFT_ADDRESS);
const ZERO_ADDR = '0x0000000000000000000000000000000000000000';

const History = () => {
  const [mintPeriods, setMintPeriods] = useState([]);
  const [data, setData] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState('');
  const [mintSwitching, setMintSwitching] = useState(false);
  const [saleActive, setSaleActive] = useState(false);
  const [totalMintedCount, setTotalMintedCount] = useState(0);

  const [transactionData, setTransactionData] = useState([])
  const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  // const { active, account, activate, deactivate } = useWeb3React();

  // const [dateList, setDateList] = useState([]);

  const showNotification = (message) => {
    if (message.includes("User denied transaction")) {
      NotificationManager.warning("User denied transaction");
    }
    else if (message.includes("caller is not the owner")) {
      NotificationManager.error("Not allowed");
    }
    else if (message.includes("is missing role")) {
      NotificationManager.error("Not allowed action");
    }
    else {
      // NotificationManager.error("Transaction is failed");
    }
  }

  useEffect(async() => {
    axios.get("/api/mintStatus")
      .then((res) => {
        if (res && res.data && res.data.status) {
          var periods = res.data.data;
          periods.map((item) => {
            // item.from = new Date(item.fromDate).toLocaleDateString();
            // item.to = new Date(item.toDate).toLocaleDateString();
            item.from = new Date(item.fromDate).toLocaleString();
            item.to = new Date(item.toDate).toLocaleString();
          })
          setMintPeriods(periods);
          console.log(periods)
        }
      })
      .catch(function(error) {
        NotificationManager.error("Error!");
      });
    
    contract.getPastEvents('Transfer', {
      fromBlock: 0,
      toBlock: 'latest',
    })
    .then((events) => {
      const sortedEvents = events.sort((a, b) => b.blockNumber - a.blockNumber);
      const mintEvents = sortedEvents.filter((event) => {
        return event.returnValues.to != ZERO_ADDR;
      })
      Promise.all(mintEvents.map((event) => {
        return web3.eth.getBlock(event.blockNumber)
          .then((res) => {
            var date = new Date(res.timestamp * 1000);
            return {
              id: 0,
              hash: event.transactionHash,
              // date: date.toLocaleDateString(),
              date: date,
              wallet: event.returnValues.to,
              count: 1
            };
          });
      }))
      .then((results) => {
        console.log(results);
        var list = [];
        results.map((item) => {
          if (list.length == 0) {
            list.push(item);
          }
          else {
            let prev = list[list.length - 1];
            if (prev.hash === item.hash && prev.wallet === item.wallet) {
              prev.count++;
            }
            else {
              item.id = list.length;
              list.push(item);
            }
          }
        });
     
        // console.log(list);

        setData(list);
      })
    })
    .catch((e) => console.log(e))
  }, []);

  // useEffect(async() => {
  //   getMintStatus();
  // }, []);

  const getTotalSupply = async() => {
    return contract.methods
      .totalSupply()
      .call()
      .then((result) => {
        return {
          success: true,
          status: result,
        };
      })
      .catch((err) => {
        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  }

  const getPrice = async() => {
    return contract.methods
      .price()
      .call()
      .then((result) => {
        let price = web3.utils.fromWei(result, "ether");
        setPrice(price);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getMintedCount = async (walletAddress) => {
    try {
      const count = await contract.methods.mintedCount(walletAddress).call();
      console.log(`Minted count of ${walletAddress}: ${count}`);
    } catch (error) {
      console.error(error);
    }
  };
  
  const getMintSettingList = async() => {
    const resTotalSupply = await getTotalSupply();
    let totalSupplyCount = 0;
    if (resTotalSupply.success) {
      totalSupplyCount = resTotalSupply.status;
      // NotificationManager.success("Sucess to get total supply!");

      setTotalMintedCount(resTotalSupply.status);
    } 
    else 
      NotificationManager.error("Fail to get total supply!");

    await axios.get("/api/mintSettingList")
      .then((res) => {
        if (res && res.data && res.data.status && res.data.data.length > 0) {
          let mintHistory = res.data.data;
          let lastItem = mintHistory[mintHistory.length - 1];
          let startOfLastItem = lastItem.mintMaxCount - lastItem.mintCount;
          lastItem.mintedCount = totalSupplyCount - startOfLastItem;
          setData(mintHistory);
        }
      })
      .catch(function(error) {
        console.log(error);
        NotificationManager.error("Error!");
      });
  }

  const downloadCsv = async () => {
    contract.getPastEvents('Transfer', {
        fromBlock: 0,
        toBlock: 'latest',
      })
      .then((events) => {
        const mintEvents = events.filter((event) => {
          return event.returnValues.to != ZERO_ADDR;
        })
        Promise.all(mintEvents.map((event) => {
          return web3.eth.getBlock(event.blockNumber)
            .then((res) => {
              var date = new Date(res.timestamp * 1000);
              return {
                // hash: event.transactionHash,
                Date: date.toLocaleDateString(),
                Wallet: event.returnValues.to,
                TokenID: event.returnValues.tokenId,
              };
            });
        }))
        .then((results) => {
          setTransactionData(results);
          csvLink.current.link.click()
        })
      })
      .catch((e) => console.log(e))
  }

  
  const PaginatedItems = ({ itemsPerPage, from, to }) => {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    console.log(dateFrom)
    console.log(dateTo)

    const items = data.filter((item) => {
      const date = new Date(item.date);
      return date >= dateFrom && date <= dateTo;
    });
    // const items = data;
    const [currentPage, setCurrentPage] = useState(1);
  
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    return (
      <div>
        {currentItems.map((item) => (
          <Grid container spacing={1} key={item.id} style={{marginBottom: '10px'}}>
            <Grid item xs={4} md={4}>
              <TextField 
                id="outlined-basic"
                type="text"
                variant="outlined"
                value={item.wallet}
                disabled
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField id="outlined-basic" type="number" variant="outlined" value={item.count} disabled />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField id="outlined-basic" variant="outlined" value={new Date(item.date).toLocaleDateString("ja-JP")} disabled />
            </Grid>
          </Grid>
        ))}
  
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              // disabled={page === currentPage}
              className={page===currentPage ? "page-link-active" : "page-link "}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    );
  };
  
  const todosList = mintPeriods.map((item, index) => (
    <Grid spacing={2} key={index} style={{marginBottom: '20px'}}>
      {item.from} ~ {item.to}
      <PaginatedItems itemsPerPage={5} from={item.fromDate} to={item.toDate}/>
    </Grid>
  ));

  return (
    <section className="container">
      <div className="card mt-4">
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h4 className="card-header">Minting management system</h4>
        </div>
        <div style={{marginLeft: '20px', marginTop: '20px', marginBottom: '20px'}}>
          <TextField id="outlined-basic" label="Total Mint Count" variant="outlined" type="number" value={3333}  style={{marginRight: '20px'}} />
          <TextField id="outlined-basic" label="Total Minted Count" variant="outlined" value={totalMintedCount} type="number" />
            <button onClick={() => downloadCsv()} style={{ border: 'none', marginLeft: '100px', marginTop: '10px'  }}>
              <img src={Img_down} className="csv-icon" alt="download" />
            </button>
          <CSVLink
            data={transactionData}
            filename='Mint_History.csv'
            className='hidden'
            ref={csvLink}
            target='_blank'
          />          
        </div>
        <Divider style={{background : 'black'}} />
        <div className="card-body ml-4 mr-4" id="container" style={{height: 'calc(100vh - 197px)', overflowY: "auto"}}>
          { todosList }

          <br/>
        </div>
      </div>
      
      <NotificationContainer />
    </section>
  );
};

export default History;